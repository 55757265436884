import { ItemList } from 'schema-dts'

import { BlogCardsBlockProps } from '../../components/molecules/BlogCardsBlock'
import { app } from '../../configuration'
import { CulinaryFolderPaginator } from '../../graphql/generated/api-graphql'
import Router, { routes } from '../../routes/Router'

export const jsonLdCulinaryFolders = (
  folders: CulinaryFolderPaginator
): ItemList => {
  return {
    '@type': 'ItemList',
    itemListElement: folders.data.map((folder, index) => ({
      '@type': 'ListItem',
      name: folder.title,
      position: index,
      item:
        app.APP_URL + Router.getRouteUrl(routes.folder, { slug: folder.slug }),
    })),
  }
}

export const jsonLdBlogFolders = (blogs: BlogCardsBlockProps): ItemList => {
  return {
    '@type': 'ItemList',
    name: blogs?.title,
    numberOfItems: blogs?.cards?.length,
    itemListElement: blogs?.cards?.map((blog, index) => ({
      '@type': 'ListItem',
      position: index,
      url: app.APP_URL + blog?.actionButtonProps?.href,
      ...('imageProps' in blog &&
        blog?.imageProps && {
          image: blog.imageProps?.images?.[0]?.src || '',
        }),
      ...('title' in blog &&
        blog?.title && {
          name: blog.title,
        }),
      ...('subtitle' in blog &&
        blog?.subtitle && {
          name: blog.subtitle,
        }),
    })),
  }
}
